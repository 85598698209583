#sentence
  width: 800px
  progress
    height: 30px
    width: 100%
  .sentence
    display: flex
    margin-top: 40px
  &:not(.active)
    progress
      visibility: hidden

.sentence-word
  position: relative
  width: 100%
  font-size: 100px
  height: 100px
  text-align: center
  margin-top: 10px

#sentence-test-instruction-page

  .sentence-instruction-wide-container
    position: relative
    .sentence-without-last-word
      position: absolute
      font-size: 16px
      font-weight: bold
      top: 2px
      left: 21px

    .sentence-last-word
      position: absolute
      font-size: 60px
      width: 100%
      left: 0
      text-align: center

    .make-sense
      position: absolute
      color: #552474
      left: 175px
      font-size: 9px
      top: 82px
      font-style: italic
      font-weight: bold
      display: inline-block
      width: 85px
      text-align: center

    .not-make-sense
      position: absolute
      color: #552474
      left: 10px
      font-size: 9px
      top: 82px
      font-style: italic
      font-weight: bold
      display: inline-block
      width: 155px
      text-align: center
  #sentence-animation
    position: relative
    span
      font-size: 75px
      text-align: center
      display: inline-block
      width: 98%
      position: absolute
      opacity: 0
      animation-duration: 6.0s
      animation-iteration-count: infinite
      animation-timing-function: step-end
    span:nth-child(1)
      animation-name: sentence-animation-1
    span:nth-child(2)
      animation-name: sentence-animation-2
    span:nth-child(3)
      animation-name: sentence-animation-3
    span:nth-child(4)
      animation-name: sentence-animation-4
    span:nth-child(5)
      animation-name: sentence-animation-5

@keyframes sentence-animation-1
  0%
    opacity: 1
  10%
    opacity: 0

@keyframes sentence-animation-2
  20%
    opacity: 1
  30%
    opacity: 0

@keyframes sentence-animation-3
  40%
    opacity: 1
  50%
    opacity: 0

@keyframes sentence-animation-4
  60%
    opacity: 1
  70%
    opacity: 0

@keyframes sentence-animation-5
  80%
    opacity: 1
  90%
    opacity: 0

@import "sass-embedded-legacy-load-done:121"