#time
  width: 800px
  progress
    height: 30px
    width: 100%
  .time
    display: flex
    margin-top: 40px

.interval
  font-size: 36px
  height: 100px
  margin-top: 67px

#time-test-instruction-page
  .image-with-word-1
    span
      font-size: 32px
      text-align: center
      display: inline-block
      position: absolute
      top: 11px
      left: 0
      width: 100%

  .image-with-word-2
    span
      position: absolute
      display: inline-block
      top: 9px
      left: 31px
      font-size: 30px

@import "sass-embedded-legacy-load-done:124"