#emotion
  width: 800px
  progress
    height: 30px
    width: 100%
  .emotion
    margin-top: 40px
  &:not(.active)
    progress
      visibility: hidden

#face
  display: block
  margin-left: auto
  margin-right: auto
  width: 400px
  height: auto

#emotion-test-instruction-page.instructions .step-1
  min-height: 216px
  margin-bottom: 10px
.emotion-instruction-narrow
  width: 165px
.emotion-instruction-wide
  width: 240px

#emotion-test-instruction-page
  #emotion-animation
    img
      position: absolute
      opacity: 0
      animation-duration: 4.8s
      animation-iteration-count: infinite
      animation-timing-function: step-end
    img:nth-child(1)
      animation-name: emotion-animation-1
    img:nth-child(2)
      animation-name: emotion-animation-2
    img:nth-child(3)
      animation-name: emotion-animation-3

@keyframes emotion-animation-1
  0%
    opacity: 1
  14%
    opacity: 0

@keyframes emotion-animation-2
  28%
    opacity: 1
  42%
    opacity: 0

@keyframes emotion-animation-3
  56%
    opacity: 1
  70%
    opacity: 0

@import "sass-embedded-legacy-load-done:0"