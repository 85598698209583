#oneback
  width: 800px
  progress
    height: 30px
    width: 100%
  .oneback
    display: flex
    margin-top: 40px
  &:not(.active)
    progress
      visibility: hidden

#oneback-test-instruction-page.instructions .step-1
  min-height: 140px
  margin-bottom: 10px

.oneback-instructions-small
  width: 180px
  margin-left: 40px

#oneback-image
  display: block
  margin-left: auto
  margin-right: auto
  width: 400px

#oneback-test-instruction-page
  #oneback-animation
    img
      position: absolute
      opacity: 0
      animation-duration: 4.8s
      animation-iteration-count: infinite
      animation-timing-function: step-end
    img:nth-child(1)
      animation-name: oneback-animation-1
    img:nth-child(2)
      animation-name: oneback-animation-2
    img:nth-child(3)
      animation-name: oneback-animation-3

@keyframes oneback-animation-1
  0%
    opacity: 1
  14%
    opacity: 0

@keyframes oneback-animation-2
  28%
    opacity: 1
  42%
    opacity: 0

@keyframes oneback-animation-3
  56%
    opacity: 1
  70%
    opacity: 0

@import "sass-embedded-legacy-load-done:119"