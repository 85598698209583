#facialrecognition
  width: 800px
  text-align: center
  progress
    height: 30px
    width: 100%
  .recognition
    display: flex
    margin-top: 40px
  &:not(.active)
    progress
      visibility: hidden

#firstface
  margin: 10px
  width: 350px
#secondface
  margin: 10px
  width: 350px

#facialrecognition-test-instruction-page.instructions .step-1
  min-height: 216px
  margin-bottom: 10px
.facialrecognition-instruction-narrow
  width: 165px
.facialrecognition-instruction-wide
  width: 240px

#facialrecognition-test-instruction-page

  .image-with-text
    position: relative
    span
      position: absolute
      left: 0
      width: 100%
      bottom: 0
      text-align: center
      font-weight: bold
      font-size: 11px

  #facialrecognition-animation
    position: relative
    img
      position: absolute
      opacity: 0
      animation-duration: 4.8s
      animation-iteration-count: infinite
      animation-timing-function: step-end
    img:nth-child(1)
      animation-name: facialrecognition-animation-1
    img:nth-child(2)
      animation-name: facialrecognition-animation-2
    img:nth-child(3)
      animation-name: facialrecognition-animation-3

@keyframes facialrecognition-animation-1
  0%
    opacity: 1
  14%
    opacity: 0

@keyframes facialrecognition-animation-2
  28%
    opacity: 1
  42%
    opacity: 0

@keyframes facialrecognition-animation-3
  56%
    opacity: 1
  70%
    opacity: 0

@import "sass-embedded-legacy-load-done:113"