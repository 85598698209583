#stop-or-go
  width: 800px
  progress
    height: 30px
    width: 100%
  .stop-or-go
    display: flex
    margin-top: 40px
  &:not(.active)
    progress
      visibility: hidden

.stop-or-go-word
  position: relative
  width: 100%
  font-size: 100px
  height: 100px
  text-align: center
  margin-top: 10px
.Blue
  color: Blue
.Red
  color: Red

#stop-or-go-test-instruction-page.instructions .step-1
  min-height: 140px
  margin-bottom: 10px

#stop-or-go-test-instruction-page
  .image-with-text
    position: relative
    span
      display: inline-block
      top: 2px
      left: 0
      width: 100%
      position: absolute
      font-size: 68px
      text-align: center
      margin-left: -10px
    .red
      color: #FF0000
    .blue
      color: #0000FF

  #stop-or-go-animation
    position: relative
    .red
      color: #FF0000
    .blue
      color: #0000FF
    span
      font-size: 75px
      text-align: center
      display: inline-block
      width: 98%
      position: absolute
      opacity: 0
      animation-duration: 4.8s
      animation-iteration-count: infinite
      animation-timing-function: step-end
    span:nth-child(1)
      animation-name: stop-or-go-animation-1
    span:nth-child(2)
      animation-name: stop-or-go-animation-2
    span:nth-child(3)
      animation-name: stop-or-go-animation-3

@keyframes stop-or-go-animation-1
  0%
    opacity: 1
  14%
    opacity: 0

@keyframes stop-or-go-animation-2
  28%
    opacity: 1
  42%
    opacity: 0

@keyframes stop-or-go-animation-3
  56%
    opacity: 1
  70%
    opacity: 0

@import "sass-embedded-legacy-load-done:122"