html, body
  min-height: 100vh
  height: auto
  min-width: 100%
body
  /* replace #fff with $body-bg when proper importing is set up
  background: #fff url(../images/jersey2.jpg) no-repeat 0 80px
  background-size: 100% 100%
  background-attachment: local
main
  max-width: 858px
  margin: 0 auto
  display: block

@import "sass-embedded-legacy-load-done:117"