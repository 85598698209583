#questionnaire
  [type=range]
    width: 85%
    margin: 0 auto
    display: block
  .zero-to-four-labels
    list-style: none
    margin: 0
    padding: 0
    display: flex
    flex-direction: row
    justify-content: space-between
    li
      margin: 0
      padding: 0
      text-align: center
      flex-basis: 20%
  .yes-no-question-options
    padding-left: 10%
    label
      display: inline
      &:not(:first-child)
        margin-left: 20px
  .question
    padding-bottom: 20px

@import "sass-embedded-legacy-load-done:120"