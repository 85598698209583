#word-pair {
  width: 800px;
  progress {
    height: 30px;
    width: 100%;
  }
  .pair {
    display: flex;
    margin-top: 40px;
    .word {
      width: 50%;
      border: 2px solid #8e588e;
      font-size: 50px;
      border-radius: 10px;
      margin: 0 5px;
      text-align: center;
    }
  }
}
#word-presentation {
  font-size: 100px;
  height: 100px;
  text-align: center;
  margin-top: 67px;
}
#word-test-instruction-page {
  #word-animation {
    position: relative;
    span {
      position: absolute;
      opacity: 0;
      animation-duration: 4.8s;
      animation-iteration-count: infinite;
      animation-timing-function: step-end;
      font-size: 75px;
      text-align: center;
      display: inline-block;
      width: 98%;
    }
    span:nth-child(1) {
      animation-name: word-animation-1;
    }
    span:nth-child(2) {
      animation-name: word-animation-2;
    }
    span:nth-child(3) {
      animation-name: word-animation-3;
    }
  }
  &.instructions .step-5 {
    min-height: 0;
  }
  .word-instructions-image-1,
  .word-instructions-image-2 {
    span {
      display: inline-block;
      font-size: 18px;
      line-height: 1.3;
      width: 126px;
      text-align: center;
      border: 1px solid #800080;
      border-radius: 5px;
    }
    .image-left {
      margin-right: 6px;
    }
  }
  .word-instructions-image-2 {
    div {
      margin-bottom: -25px;
    }
    .image-left {
      background: #ffd34e;
    }
  }
}
@keyframes word-animation-1 {
  0% {
    opacity: 1;
  }
  14% {
    opacity: 0;
  }
}

@keyframes word-animation-2 {
  28% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
}

@keyframes word-animation-3 {
  56% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
}

;@import "sass-embedded-legacy-load-done:125";