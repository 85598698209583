#tapping
  width: 800px
  progress
    height: 30px
    width: 100%
  .tapping
    display: flex
    margin-top: 40px

.intermission
  font-size: 36px
  height: 100px
  text-align: center
  margin-top: 67px

#tapping-test-instruction-page
  #tapping-animation
    img
      position: absolute
      opacity: 0
      animation-duration: 4.8s
      animation-iteration-count: infinite
      animation-timing-function: step-end
    img:nth-child(1)
      animation-name: tapping-animation-1
    img:nth-child(2)
      animation-name: tapping-animation-2
    img:nth-child(3)
      animation-name: tapping-animation-3

@keyframes tapping-animation-1
  0%
    opacity: 1
  14%
    opacity: 0

@keyframes tapping-animation-2
  28%
    opacity: 1
  42%
    opacity: 0

@keyframes tapping-animation-3
  56%
    opacity: 1
  70%
    opacity: 0

@import "sass-embedded-legacy-load-done:123"