.button-wrapper {
  display: inline-block;
  // /* TODO: use $button-margin-bottom*/
  margin-bottom: rem-calc(20);
  background-image: linear-gradient(
    #c9c8c9 0%,
    #979497 50%,
    #7d787e 50%,
    #7d787e 100%
  );
  // /* TODO: use @include radius($button-radius)*/
  border-radius: 4px;
  // margin-left: 22px;
}
button {
  margin: 0;
  z-index: 0;
  border: 1px solid #000;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  background-image: linear-gradient(
    #c9c8c9 0%,
    #979497 50%,
    #7d787e 50%,
    #7d787e 100%
  );
  &:not(.tiny):not(.small):not(.large) {
    padding: 3px 30px;
  }
  &:before,
  &:after {
    border-radius: inherit;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
  &:before {
    z-index: -2;
  }
  &:after {
    z-index: -2;
  }
  &:hover {
    background-image: radial-gradient(
      at 50% 130%,
      rgba(141, 189, 255, 0.7) 0%,
      rgba(141, 189, 255, 0) 100%
    );
    transition: opacity 0.3s;
    opacity: 1;
    cursor: pointer;
  }
  &:active:before {
    transition: opacity 0.4s;
    opacity: 1;
  }
}
h2 {
  font-family: Arial;
  color: #552474;
}

;@import "sass-embedded-legacy-load-done:114";