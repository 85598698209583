.instructions
  /* TODO: change to rem-calc
  font-size: 14px
  p
    font-size: inherit
  .row
    padding-bottom: 10px
    .columns:last-child
      padding-left: 10px
  @each $row in 1 2 4 5
    .step-#{$row}
      min-height: 100px

@import "sass-embedded-legacy-load-done:116"