#login
  width: 640px
  margin: auto
  progress
    height: 30px
    width: 100%
  .instructions p
    text-align: center
    font-weight: bold
    margin: 26px 0
  label
    font-weight: bold
    text-align: right
  input
    width: 200px

@import "sass-embedded-legacy-load-done:118"