#main-header
  background: #e3e2e2
  margin: 0 0 26px 0
  height: 90px
  img
    margin-left: 24px
    height: 88px

  .small
    font-size: 15px

@import "sass-embedded-legacy-load-done:115"